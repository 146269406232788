<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF DEPOSITS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
          <span class="headline font-weight-light"
                v-if="collectors_data.length>0">
                                                Print All Deposit Slip
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_all_deposit_slip()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="collectors_data"
      >
        <template v-slot:item="{ item }">
          <tr
            @click="activerow(item)"
            :class="{'info black--text': item.id===selectedDepositId}"
          >
            <td>{{ item.id }}</td>
            <td>
              {{ item.date_of_deposit }}
            </td>
            <td>
              {{ item.time_of_deposit }}
            </td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>
            <td>
              {{ remittances_amount(item) }}
            </td>
            <td>
              {{ formatPrice(item.balance) }}
            </td>
            <td>
              {{ income_amount(item) }}
            </td>
            <td>
              {{ item.bank_depositories.bank_code_name}}
            </td>
            <td v-if="position==='ADMIN'|| position==='MANAGER'">
              <div v-if="item.is_audited===1">
                <v-chip color="success" dark>DONE</v-chip>
              </div>
              <div v-else-if="item.is_audited===0">
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="actions(item,1)"
                >
                  {{icons.mdiToggleSwitch}}
                </v-icon>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="remittances_dialog" width="70%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CORE PORTAL TRANSACTION</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-row class="pt-6 mx-4">
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.date_of_deposit"
                          label="Date Deposited"
                          dense
                          outlined
                          style="font-size: medium"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.time_of_deposit"
                          label="Time Deposited"
                          dense
                          outlined
                          style="font-size: medium"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="bank_depositories.bank_code_name"
                          label="Bank Code"
                          dense
                          outlined
                          style="font-size: medium"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.amount"
                          label="Deposit Amount"
                          dense
                          outlined
                          style="font-size: xx-large"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="total_remittances"
                          label="Total Remittances"
                          dense
                          outlined
                          style="font-size: xx-large"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.balance"
                          label="Balance"
                          dense
                          outlined
                          style="font-size: xx-large"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LIST OF TRANSACTION</h4>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <span class="headline font-weight-light white--text"
                v-if="collectors_remittances_data.length>0">
                                                Print Transaction Slip
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_selected_deposit_slip_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
        </v-toolbar>
        <v-data-table dense
                      :headers="headers_remittance"
                      :items="collectors_remittances_data">
          <template v-slot:item="{ item }">
            <tr
            >
              <td>
                {{ item.category
                }}
              </td>
              <td>
                {{ item.particulars.category }}
              </td>
              <td>
                {{ item.particulars.particulars }}
              </td>
              <td>
                {{ date_format(item.created_at).format('MMMM DD, YYYY HH:mm:ss') }}
              </td>
              <td>
                {{ item.reference_no }}
              </td>
              <td>
                {{ formatPrice(item.amount) }}
              </td>
              <td>
                {{ formatPrice(item.billers_fee) }}
              </td>
              <td>
                {{ formatPrice(item.processing_fee) }}
              </td>
              <td>
                {{ formatPrice(item.fee) }}
              </td>
              <td>
                {{ formatPrice(item.total) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPrinter, mdiDelete, mdiEyeOutline, mdiToggleSwitch} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from 'moment'

  export default {
    components: {
      snackBarDialog,
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.get_transaction_month_history(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiEyeOutline,
          mdiToggleSwitch,
        },
      }
    },
    data() {
      return {
        total_dep: '0.00',
        total_remit: '0.00',
        total_incom: '0.00',
        headers: [
          {text: 'ID', value: 'id', sortable: false},
          {text: 'DATE', value: 'date_of_deposit', sortable: false},
          {text: 'TIME', value: 'time_of_deposit', sortable: false},
          {text: 'AMOUNT', value: 'amount', sortable: false},
          {text: 'REMITTANCES', value: 'amount', sortable: false},
          {text: 'BALANCE', value: 'amount', sortable: false},
          {text: 'INCOME', value: 'amount', sortable: false},
          {text: 'BANK', value: 'bank_code_name', sortable: false},
          {text: 'AUDITED', value: 'bank_code_name', sortable: false},],
        headers_remittance: [
          {text: 'Option', value: 'id', sortable: false},
          {text: 'Category', value: 'date_of_deposit', sortable: false},
          {text: 'Particulars', value: 'time_of_deposit', sortable: false},
          {text: 'Date', value: 'amount', sortable: false},
          {text: 'Reference #', value: 'bank_code_name', sortable: false},
          {text: 'Amount', value: 'amount', sortable: false},
          {text: 'Biller Fee', value: 'amount', sortable: false},
          {text: 'Processing Fee', value: 'amount', sortable: false},
          {text: 'System Fee', value: 'amount', sortable: false},
          {text: 'Total', value: 'amount', sortable: false},
        ],
        remittances_dialog: false,

        data: [],
        bank_depositories: [],
        collectors_data: [],
        collectors_remittances_data: [],

        month_of_items: [],
        month_of: '',
        total_remittances: '0.00',
        selectedDepositId: -1,
      }
    },
    computed: {
      ...mapGetters('authentication', ['position', 'branch_id', 'branch_contact_no', 'branch_address', 'branch', 'branch_selected','branch_id_selected']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('deposit_slip', ['remittances_data', 'change_is_audited']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      monthly_remittances() {
        var index = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of)
        if (index != -1) {
          const data = new FormData()
          data.append('branch_id', this.branch_id===1?this.branch_id_selected:this.branch_id);
          data.append('month_of', this.month_of_items[index].month_of);
          this.remittances_data(data)
            .then(response => {
              this.selectedDepositId = -1
              this.collectors_data = response.data
              this.collectors_remittances_data = []
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      activerow(value) {
        this.total_remittances = this.remittances_amount(value)
        this.data = value
        this.bank_depositories = value.bank_depositories
        this.remittances_dialog = true
        this.selectedDepositId = value.id;
        this.collectors_remittances_data = value.liquidations
      },
      actions(item) {
        const data = new FormData()
        data.append('id', item.id);
        this.change_is_audited(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            this.monthly_remittances()
          })
          .catch(error => {
            console.log(error)
          })
      },
      remittances_amount(value) {
        var tot_amount = 0;
        value.liquidations.forEach(function (item) {
          tot_amount += parseFloat(item.total)
        });

        return this.formatPrice(tot_amount);
      },
      income_amount(value) {
        var tot_amount = 0;
        value.liquidations.forEach(function (item) {
          tot_amount += parseFloat(item.fee)
        });

        return this.formatPrice(tot_amount);
      },
      print_selected_deposit_slip_data() {
        var imgData = this.company_logo
        var payments_array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)
        var month = this.month_of_items[indexMonth].month_of
        var dep_id = this.data.id
        var dep_amount = this.data.amount
        var total_diff = this.data.balance

        var total_fee = 0
        var total_net = 0
        var total_billers_fee = 0
        var total_processing_fee = 0
        var total_amount = 0
        payments_array.push(
          [
            {text: 'Option', alignment: 'center', style: 'label'},
            {text: 'Category', alignment: 'center', style: 'label'},
            {text: 'Particulars', alignment: 'center', style: 'label'},
            {text: 'Date', alignment: 'center', style: 'label'},
            {text: 'Reference #', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
            {text: 'Biller Fee', alignment: 'center', style: 'label'},
            {text: 'Processing Fee', alignment: 'center', style: 'label'},
            {text: 'System Fee', alignment: 'center', style: 'label'},
            {text: 'Total', alignment: 'center', style: 'label'},
          ]
        )
        if (this.collectors_remittances_data.length > 0) {
          this.collectors_remittances_data.forEach(function (item, index, payment) {
            total_net += parseFloat(item.total)
            total_billers_fee += parseFloat(item.billers_fee)
            total_processing_fee += parseFloat(item.processing_fee)
            total_amount += parseFloat(item.amount)
            total_fee += parseFloat(item.fee)
            payments_array.push(
              [
                {text: item.category, alignment: 'center'},
                {text: item.particulars.category, alignment: 'center'},
                {text: item.particulars.particulars, alignment: 'center'},
                {
                  text: moment(item.created_at).format('MMMM DD, YYYY HH:mm:ss'),
                  alignment: 'center'
                },
                {text: item.reference_no, alignment: 'center'},
                {
                  text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
                {
                  text: (item.billers_fee / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
                {
                  text: (item.processing_fee / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
                {
                  text: (item.fee / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
                {
                  text: (item.total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_billers_fee / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_processing_fee / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_fee / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_net / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'LANDSCAPE',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GL EXPRESS PERA PADALA',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: '* CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'TRANSACTION SLIP', style: 'title'},
            '=================================================================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            {text: 'Dep. ID: ' + dep_id, style: 'main_info', color: 'red'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [85, 85, 150, 120, 85, 50, 50, 50, 50, 50],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Teller', style: 'aa_placeholder'},
                    {text: 'Head Office', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with GL-Express System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
      print_all_deposit_slip() {
        var imgData = this.company_logo
        var payments_array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)
        var month = this.month_of_items[indexMonth].month_of

        var total_amount = 0
        payments_array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'DATE', alignment: 'center', style: 'label'},
            {text: 'TIME', alignment: 'center', style: 'label'},
            {text: 'AMOUNT', alignment: 'center', style: 'label'},
            {text: 'REMITTANCES', alignment: 'center', style: 'label'},
            {text: 'BALANCE', alignment: 'center', style: 'label'},
            {text: 'INCOME', alignment: 'center', style: 'label'},
            {text: 'BANK', alignment: 'center', style: 'label'},
          ]
        )
        if (this.collectors_data.length > 0) {
          var total_amount = 0
          var total_remmittances = 0
          var total_income = 0
          this.collectors_data.forEach(function (item, index, payment) {
            total_amount += parseFloat(item.amount)
            var remit = 0
            var income = 0
            item.liquidations.forEach(function (item2) {
              remit += parseFloat(item2.total)
              income += parseFloat(item2.fee)
            });
            total_remmittances += parseFloat(remit)
            total_income += parseFloat(income)
            payments_array.push(
              [
                {text: item.id, alignment: 'center'},
                {text: item.date_of_deposit, alignment: 'center'},
                {text: item.time_of_deposit, alignment: 'center'},
                {
                  text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
                {
                  text: (remit / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
                {
                  text: ((parseFloat(item.amount) - parseFloat(remit)) / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
                {
                  text: (income / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
                {text: item.bank_depositories.bank_code_name, alignment: 'center'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_remmittances / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                  {
                    text: ((parseFloat(total_amount) - parseFloat(total_remmittances)) / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_income / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'PORTRAIT',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GL EXPRESS PERA PADALA',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: '* CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'DEPOSIT SLIPS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch_selected, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [30, 60, 40, 60, 60, 60, 60, 100,],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Teller', style: 'aa_placeholder'},
                    {text: 'Head Office', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with GL-Express System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
